import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const paragraphStyles = {
  marginBottom: 48,
}

// markup
const Presentation = () => {
  return (
    <main style={pageStyles}>
      <title>Présentation</title>
      <h1 style={headingStyles}>
        Présentation
      </h1>
      <p style={paragraphStyles}>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugiat reiciendis nostrum, recusandae quasi saepe quos dolorum nisi unde necessitatibus ea. Animi, repudiandae! Dolores ratione atque repellendus vel, maiores rem laudantium?
        Praesentium nemo rem magni voluptas amet odit pariatur inventore ducimus ipsam deserunt ex nesciunt veritatis velit minima aliquid nihil, optio quibusdam odio incidunt facilis minus officia sint dolor expedita. Quisquam!
        Voluptatem consectetur numquam quibusdam libero debitis voluptas nemo ipsa? Sed enim dolorem, molestiae quasi ducimus voluptates optio ex qui expedita eos praesentium tenetur perspiciatis velit fugit maiores hic nemo modi.
        Eveniet praesentium cum, dolores labore dolorem exercitationem. Blanditiis delectus aspernatur repellat nesciunt quae, earum alias fugiat doloribus animi itaque officiis hic temporibus consectetur doloremque praesentium maiores assumenda dolorem deleniti placeat.
        Laboriosam pariatur excepturi possimus voluptatum explicabo eligendi, vel ea aperiam vero, saepe modi beatae nulla et quod esse sapiente facere, aspernatur exercitationem! Impedit ratione voluptas velit? Animi, aliquam. Laudantium, vitae!
        Pariatur sapiente eius praesentium, voluptates, ipsum accusantium delectus qui, quaerat minima ducimus quo nesciunt quibusdam atque facilis doloribus sequi ipsa ipsam repudiandae eveniet et? Officia voluptates repellat eveniet architecto illum?
        Perspiciatis, nobis maiores quia tenetur cupiditate ad reiciendis error illo adipisci deserunt placeat in consectetur earum? Hic iure temporibus vel recusandae repellendus quam, labore alias tempora sapiente aspernatur explicabo inventore.
        Maiores ad incidunt neque, voluptas mollitia, dolore saepe officia voluptatem porro delectus sequi molestiae laudantium error corporis rem esse perferendis, aliquid illo labore architecto. Quasi ipsa molestiae consequuntur sint vero.
        Saepe commodi quis obcaecati aperiam, iusto dolorum voluptas? Quam tenetur laudantium, eaque harum placeat ex sit voluptatem perferendis recusandae tempore? Distinctio aperiam eius ipsa, velit impedit cumque ullam est neque.
        Repudiandae, dignissimos at! Quos necessitatibus dicta nesciunt debitis quam excepturi eos autem recusandae ratione suscipit veritatis est eum atque dolores blanditiis quas nulla dolorem quasi corporis sequi quis, ab minus!
        Quam earum quaerat eos. Explicabo facilis aspernatur quisquam deserunt ipsum esse alias, tenetur provident eligendi pariatur dignissimos sapiente quod, voluptas consectetur rerum odio corporis optio doloremque architecto? Obcaecati, similique odit.
        Ipsum ducimus officiis magni, fuga praesentium consequuntur nesciunt quas reiciendis itaque harum est alias. Eligendi, necessitatibus dolorum at tenetur quam nulla inventore reiciendis tempore beatae debitis explicabo amet excepturi sapiente.
        Mollitia modi omnis distinctio doloremque ipsum quas doloribus non, tempore dolore magnam ut. Veritatis dolore repellat ratione cum accusantium pariatur suscipit ipsam repudiandae ipsum quos impedit rem, deleniti non vero.
        Corporis animi autem id iure doloremque! Iusto nihil mollitia rerum voluptate quisquam doloremque, nesciunt itaque quaerat explicabo culpa magni magnam modi temporibus molestiae, quae nostrum accusamus corporis. Veniam, nisi facilis!
        Porro, voluptatem vitae quaerat dolore modi perferendis vel neque nostrum? Expedita aut, doloremque quam dolores nulla voluptatibus beatae eius quasi veritatis perspiciatis cumque impedit tenetur exercitationem blanditiis quibusdam tempora! Aspernatur.
        Soluta asperiores dolores itaque laborum quidem perspiciatis iste assumenda harum ipsum nesciunt doloremque, ex molestias? Tempora vitae, vel cupiditate quidem alias architecto tempore saepe, accusantium qui atque similique repellat pariatur?
        Dolorem laboriosam eius aliquid laudantium facere sequi earum porro autem dolorum, ut voluptatibus officia quae aperiam natus deleniti quos amet vitae deserunt fugiat ullam. Eos, voluptatibus tempore. Accusantium, delectus fugiat?
        Fuga beatae quidem qui. Voluptatibus, ullam dicta? Optio, sunt! Ex nemo ratione quia eveniet, illum illo, voluptas provident asperiores reiciendis doloremque debitis mollitia amet totam atque soluta culpa porro? Maiores.
        Eos dolorem voluptatibus, praesentium iusto provident optio, inventore sapiente dolorum modi perspiciatis amet illo. Eligendi, corrupti, natus labore quia tempora eos non magnam doloremque esse vel officia cum earum cumque.
        Eum quae esse expedita dicta at, iste, id fuga officia recusandae maiores pariatur libero harum consectetur, non aliquid labore. Tempore autem natus iste! Alias a soluta dolore atque maxime nobis!
      </p>
    </main>
  )
}

export default Presentation
